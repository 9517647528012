// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SitesPerformanceChart_lineIcon__1dRHC {\n  padding-bottom: 0.4rem;\n  padding-right: 0.5rem;\n}\n\n.SitesPerformanceChart_loading__3HivU {\n  position: relative;\n  top: 4.5rem;\n  display: flex;\n  justify-content: center;\n  background-color: transparent;\n  height: 0;\n}\n\n.SitesPerformanceChart_legendContainer__1th0z {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1rem;\n}\n\n.SitesPerformanceChart_yUnitLabel__dZDcl {\n  width: 2rem;\n  text-align: center;\n  margin-bottom: 0.5rem;\n  font-size: 1rem;\n  color: var(--base50);\n}\n\n.SitesPerformanceChart_marginRight__1yh2G {\n  margin-right: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/views/report/SitesPerformanceChart/SitesPerformanceChart.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,6BAA6B;EAC7B,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".lineIcon {\n  padding-bottom: 0.4rem;\n  padding-right: 0.5rem;\n}\n\n.loading {\n  position: relative;\n  top: 4.5rem;\n  display: flex;\n  justify-content: center;\n  background-color: transparent;\n  height: 0;\n}\n\n.legendContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1rem;\n}\n\n.yUnitLabel {\n  width: 2rem;\n  text-align: center;\n  margin-bottom: 0.5rem;\n  font-size: 1rem;\n  color: var(--base50);\n}\n\n.marginRight {\n  margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineIcon": "SitesPerformanceChart_lineIcon__1dRHC",
	"loading": "SitesPerformanceChart_loading__3HivU",
	"legendContainer": "SitesPerformanceChart_legendContainer__1th0z",
	"yUnitLabel": "SitesPerformanceChart_yUnitLabel__dZDcl",
	"marginRight": "SitesPerformanceChart_marginRight__1yh2G"
};
export default ___CSS_LOADER_EXPORT___;
