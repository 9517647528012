// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SensorInfoContainer_root__ZpF4a {\n  min-height: 2.125rem;\n  padding: 0.5rem 0;\n  display: flex;\n  justify-content: space-between;\n  font-size: 1.125rem;\n}\n\n.SensorInfoContainer_empty__1HXFh {\n  width: 15rem;\n}\n", "",{"version":3,"sources":["webpack://src/containers/EquipmentReport/SensorInfoContainer/SensorInfoContainer.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".root {\n  min-height: 2.125rem;\n  padding: 0.5rem 0;\n  display: flex;\n  justify-content: space-between;\n  font-size: 1.125rem;\n}\n\n.empty {\n  width: 15rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SensorInfoContainer_root__ZpF4a",
	"empty": "SensorInfoContainer_empty__1HXFh"
};
export default ___CSS_LOADER_EXPORT___;
