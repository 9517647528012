// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EquipmentEnergyChartContainer_chartTitle__1ynby {\n  font-size: 2rem;\n  color: var(--accent-base);\n  margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/containers/EquipmentEnergyChartContainer/EquipmentEnergyChartContainer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".chartTitle {\n  font-size: 2rem;\n  color: var(--accent-base);\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartTitle": "EquipmentEnergyChartContainer_chartTitle__1ynby"
};
export default ___CSS_LOADER_EXPORT___;
