// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemperatureRecordReportTable_tableContainer__3C1Id {\n  padding: 2rem 0;\n  min-height: 1700px;\n}\n\n.TemperatureRecordReportTable_headerCell__UVOCV {\n  font-size: 1.5rem;\n  font-weight: bold;\n  white-space: nowrap;\n}\n\n.TemperatureRecordReportTable_cell__15gwO {\n  font-size: 1.25rem;\n  min-height: 3rem;\n}\n\n.TemperatureRecordReportTable_sensorTitleCell__2WzbA {\n  white-space: nowrap;\n  min-height: 3rem;\n}\n\n.TemperatureRecordReportTable_leftPadding__3uDKD {\n  padding-left: 0.75rem;\n}\n\n.TemperatureRecordReportTable_notificationCellContent__G-MnU {\n  font-size: 0.875rem;\n  font-style: italic;\n  margin-top: 0.25rem;\n}\n", "",{"version":3,"sources":["webpack://src/views/report/TemperatureRecordReportTable/TemperatureRecordReportTable.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".tableContainer {\n  padding: 2rem 0;\n  min-height: 1700px;\n}\n\n.headerCell {\n  font-size: 1.5rem;\n  font-weight: bold;\n  white-space: nowrap;\n}\n\n.cell {\n  font-size: 1.25rem;\n  min-height: 3rem;\n}\n\n.sensorTitleCell {\n  white-space: nowrap;\n  min-height: 3rem;\n}\n\n.leftPadding {\n  padding-left: 0.75rem;\n}\n\n.notificationCellContent {\n  font-size: 0.875rem;\n  font-style: italic;\n  margin-top: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "TemperatureRecordReportTable_tableContainer__3C1Id",
	"headerCell": "TemperatureRecordReportTable_headerCell__UVOCV",
	"cell": "TemperatureRecordReportTable_cell__15gwO",
	"sensorTitleCell": "TemperatureRecordReportTable_sensorTitleCell__2WzbA",
	"leftPadding": "TemperatureRecordReportTable_leftPadding__3uDKD",
	"notificationCellContent": "TemperatureRecordReportTable_notificationCellContent__G-MnU"
};
export default ___CSS_LOADER_EXPORT___;
