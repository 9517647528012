// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ACConsumption_content__3wllU {\n  display: flex;\n  flex-direction: column;\n}\n\n.ACConsumption_headerContainer__4fwYX {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\n.ACConsumption_headerIcon__1qDLj {\n  color: var(--accent-base);\n  display: block;\n  padding-right: 1rem;\n}\n\n.ACConsumption_cell__xje8_ {\n  font-size: 0.875rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/GenericReport/ACConsumption/ACConsumption.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n}\n\n.headerContainer {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\n.headerIcon {\n  color: var(--accent-base);\n  display: block;\n  padding-right: 1rem;\n}\n\n.cell {\n  font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ACConsumption_content__3wllU",
	"headerContainer": "ACConsumption_headerContainer__4fwYX",
	"headerIcon": "ACConsumption_headerIcon__1qDLj",
	"cell": "ACConsumption_cell__xje8_"
};
export default ___CSS_LOADER_EXPORT___;
