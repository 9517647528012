// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrgSwitch_root__3j9G0 {\n  display: grid;\n  grid-template-columns: 8.5rem 12.5rem 12.5rem 8.5rem;\n  grid-column-gap: 1rem;\n  grid-row-gap: 2rem;\n  margin: 2.5rem;\n  padding: 2rem;\n  font-size: 1em;\n}\n\n.OrgSwitch_root__3j9G0:not(:last-child) {\n  margin-bottom: 3rem;\n}\n", "",{"version":3,"sources":["webpack://src/containers/OrgSwitch/OrgSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oDAAoD;EACpD,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: 8.5rem 12.5rem 12.5rem 8.5rem;\n  grid-column-gap: 1rem;\n  grid-row-gap: 2rem;\n  margin: 2.5rem;\n  padding: 2rem;\n  font-size: 1em;\n}\n\n.root:not(:last-child) {\n  margin-bottom: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OrgSwitch_root__3j9G0"
};
export default ___CSS_LOADER_EXPORT___;
