// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingBar_root__20ZVW {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\n.LoadingBar_progress__29b0j {\n  color: var(--ambient-base);\n}\n", "",{"version":3,"sources":["webpack://src/components/LoadingBar/LoadingBar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".root {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\n.progress {\n  color: var(--ambient-base);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LoadingBar_root__20ZVW",
	"progress": "LoadingBar_progress__29b0j"
};
export default ___CSS_LOADER_EXPORT___;
