// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NOHEfficiencyLowestSites_tileContainer__-1hoM {\n  font-size: 1.2rem;\n  line-height: 1.5rem;\n}\n\n.NOHEfficiencyLowestSites_tileTitle__2ccby {\n  font-size: 1.5rem;\n  margin-bottom: 1.5rem;\n}\n\n.NOHEfficiencyLowestSites_topTable__15zUF {\n  font-size: 1rem;\n}\n\n.NOHEfficiencyLowestSites_topTable__15zUF thead tr, .NOHEfficiencyLowestSites_topTable__15zUF thead tr th {\n  border-top: none !important;\n}\n\n.NOHEfficiencyLowestSites_tableHeader__1kZgr div {\n  font-size: 1rem;\n  font-weight: 600;\n  word-wrap: break-word;\n  white-space: normal;\n}\n\n.NOHEfficiencyLowestSites_tableGroupHeader__1KywG {\n  font-size: 1.2rem !important;\n  font-weight: 600;\n}\n\n.NOHEfficiencyLowestSites_red__3Hk3r {\n  color: var(--notification-red);\n}", "",{"version":3,"sources":["webpack://src/views/report/NOHEfficiencyLowestSites/NOHEfficiencyLowestSites.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".tileContainer {\n  font-size: 1.2rem;\n  line-height: 1.5rem;\n}\n\n.tileTitle {\n  font-size: 1.5rem;\n  margin-bottom: 1.5rem;\n}\n\n.topTable {\n  font-size: 1rem;\n}\n\n.topTable thead tr, .topTable thead tr th {\n  border-top: none !important;\n}\n\n.tableHeader div {\n  font-size: 1rem;\n  font-weight: 600;\n  word-wrap: break-word;\n  white-space: normal;\n}\n\n.tableGroupHeader {\n  font-size: 1.2rem !important;\n  font-weight: 600;\n}\n\n.red {\n  color: var(--notification-red);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tileContainer": "NOHEfficiencyLowestSites_tileContainer__-1hoM",
	"tileTitle": "NOHEfficiencyLowestSites_tileTitle__2ccby",
	"topTable": "NOHEfficiencyLowestSites_topTable__15zUF",
	"tableHeader": "NOHEfficiencyLowestSites_tableHeader__1kZgr",
	"tableGroupHeader": "NOHEfficiencyLowestSites_tableGroupHeader__1KywG",
	"red": "NOHEfficiencyLowestSites_red__3Hk3r"
};
export default ___CSS_LOADER_EXPORT___;
