// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WeatherAndEnergyConsumptionContainer_content__3gE-q {\n  display: flex;\n  flex-direction: column;\n}\n\n.WeatherAndEnergyConsumptionContainer_headerContainer__xOtpJ {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://src/containers/GenericReport/WeatherAndEnergyConsumptionContainer/WeatherAndEnergyConsumptionContainer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n}\n\n.headerContainer {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "WeatherAndEnergyConsumptionContainer_content__3gE-q",
	"headerContainer": "WeatherAndEnergyConsumptionContainer_headerContainer__xOtpJ"
};
export default ___CSS_LOADER_EXPORT___;
