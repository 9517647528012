import React, { useEffect, useMemo, useState } from 'react';
import styles from '../ReportPage.module.css';
import flatten from 'ramda/src/flatten';

type Props = {
  fetchedStatuses: any;
  headerForNewPages?: React.ReactNode;
  headerRef?: any;
};

const ReportPageBreakControl: React.FC<Props> = ({
  children,
  fetchedStatuses = {},
  headerForNewPages,
  headerRef,
}) => {
  const [isUpdated, setIsUpdated] = useState(false);
  const fetchedStates: boolean[] = useMemo(() => {
    return flatten(
      Object.values(fetchedStatuses).map((el: any) => {
        if (typeof el === 'object') {
          return Object.values(el);
        }
        return el;
      })
    );
  }, [fetchedStatuses]);

  const isAllFetched = useMemo(() => {
    return fetchedStates.filter(state => !state).length === 0;
  }, [fetchedStates]);

  useEffect(() => {
    if (isAllFetched && !isUpdated) {
      setTimeout(() => {
        setIsUpdated(true);
        // @ts-ignore, custom window boolean
        window.reportReady = true;
      }, 1000);
    }
  }, [isUpdated, isAllFetched]);

  const pageHeight = 2025;
  let totalHeight = 0;
  let enrichedChildren = [];

  React.Children.forEach(children, (child, index) => {
    // @ts-ignore
    const newComponentHeight = child?.props?.setRef?.current?.clientHeight || 0;
    const shouldStartNewPage = newComponentHeight + totalHeight > pageHeight;

    if (!!shouldStartNewPage && !!headerForNewPages && !!headerRef) {
      enrichedChildren.push(
        // @ts-ignore
        React.cloneElement(headerForNewPages, {
          currentTotalHeight: totalHeight,
          updatePage: () => setIsUpdated(true),
          pageHeight,
          shouldStartNewPage,
        })
      );
      totalHeight = headerRef?.current?.clientHeight;
    }

    // @ts-ignore
    enrichedChildren.push(
      // @ts-ignore
      React.cloneElement(child, {
        key: `report-element-${index + 1}`,
        currentTotalHeight: totalHeight,
        updatePage: () => setIsUpdated(true),
        pageHeight,
      })
    );

    totalHeight = (totalHeight + newComponentHeight) % pageHeight;
    // use remainder in case single component spans across pages, for example long table
    // notes: the generated margin is still not correct using this line nor this:
    // totalHeight = totalHeight + newComponentHeight
  });

  return (
    <div data-testid="pagebreakControl" className={styles.pageContainer}>
      {enrichedChildren}
    </div>
  );
};

export default ReportPageBreakControl;
