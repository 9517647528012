// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NOHEfficiencyTile_link__1ag6N {\n  /* position: absolute; */\n  width: 100%;\n  font-size: 12px;\n  font-weight: 600;\n  color: #00a1af;\n  text-align: right;\n  bottom: 0;\n  margin-top: 1rem;\n}\n\n.NOHEfficiencyTile_link__1ag6N a:hover,\n.NOHEfficiencyTile_link__1ag6N a:visited {\n  text-decoration: none;\n  color: #00a1af;\n}\n\n.NOHEfficiencyTile_arrow__vB6vw {\n  position: relative;\n  top: 0.1rem;\n}\n\n.NOHEfficiencyTile_tileTitle__9f8RT {\n  margin-bottom: 1rem;\n  font-size: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/views/report/NOHEfficiencyLineChart/NOHEfficiencyTile.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,SAAS;EACT,gBAAgB;AAClB;;AAEA;;EAEE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".link {\n  /* position: absolute; */\n  width: 100%;\n  font-size: 12px;\n  font-weight: 600;\n  color: #00a1af;\n  text-align: right;\n  bottom: 0;\n  margin-top: 1rem;\n}\n\n.link a:hover,\n.link a:visited {\n  text-decoration: none;\n  color: #00a1af;\n}\n\n.arrow {\n  position: relative;\n  top: 0.1rem;\n}\n\n.tileTitle {\n  margin-bottom: 1rem;\n  font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "NOHEfficiencyTile_link__1ag6N",
	"arrow": "NOHEfficiencyTile_arrow__vB6vw",
	"tileTitle": "NOHEfficiencyTile_tileTitle__9f8RT"
};
export default ___CSS_LOADER_EXPORT___;
