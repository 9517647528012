// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportErrorPageContainer_root__3r_bc {\n  width: 100%;\n  height: 700px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 2rem;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/views/report/ReportErrorPageContainer/ReportErrorPageContainer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".root {\n  width: 100%;\n  height: 700px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 2rem;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ReportErrorPageContainer_root__3r_bc"
};
export default ___CSS_LOADER_EXPORT___;
