// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultLayout_root__11Qj- {\n  display: flex;\n  width: 100vw;\n}\n\n.DefaultLayout_pdfRoot__1UjYI {\n  width: 100%;\n  height: 100vh;\n}\n\n.DefaultLayout_fullFrame__kv6Qb {\n  height: calc(100% - var(--top-bar-menu-height));\n}\n\n.DefaultLayout_content__KXu3E {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/containers/layout/DefaultLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  display: flex;\n  width: 100vw;\n}\n\n.pdfRoot {\n  width: 100%;\n  height: 100vh;\n}\n\n.fullFrame {\n  height: calc(100% - var(--top-bar-menu-height));\n}\n\n.content {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DefaultLayout_root__11Qj-",
	"pdfRoot": "DefaultLayout_pdfRoot__1UjYI",
	"fullFrame": "DefaultLayout_fullFrame__kv6Qb",
	"content": "DefaultLayout_content__KXu3E"
};
export default ___CSS_LOADER_EXPORT___;
