// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SitesPerformanceTile_tileContainer__2AR0K {\n\n}\n\n.SitesPerformanceTile_tileTitle__1PiIl {\n  margin-bottom: 1rem;\n  font-size: 1.5rem;\n}", "",{"version":3,"sources":["webpack://src/views/report/SitesPerformanceTile/SitesPerformanceTile.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".tileContainer {\n\n}\n\n.tileTitle {\n  margin-bottom: 1rem;\n  font-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tileContainer": "SitesPerformanceTile_tileContainer__2AR0K",
	"tileTitle": "SitesPerformanceTile_tileTitle__1PiIl"
};
export default ___CSS_LOADER_EXPORT___;
