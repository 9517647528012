// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SitesPage_root__3XG7n {\n  padding: 2rem;\n}\n\n.SitesPage_headerContainer__1sHPL {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.SitesPage_headerTitle__1vVuq {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/views/sites/SitesPage/SitesPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".root {\n  padding: 2rem;\n}\n\n.headerContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.headerTitle {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SitesPage_root__3XG7n",
	"headerContainer": "SitesPage_headerContainer__1sHPL",
	"headerTitle": "SitesPage_headerTitle__1vVuq"
};
export default ___CSS_LOADER_EXPORT___;
