// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BaseContentContainer_root__2c3-S {\n  position: relative;\n}\n\n.BaseContentContainer_capHeight__3ePIb {\n  max-height: 100%;\n}\n\n.BaseContentContainer_margin__XnSfX {\n  padding: 2rem 3rem;\n}\n\n.BaseContentContainer_fillFrame__15itn {\n  height: 100%;\n}\n\n.BaseContentContainer_hasFilterBar__4OD5G {\n  padding: 2.5rem 3.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/layout/BaseContentContainer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".root {\n  position: relative;\n}\n\n.capHeight {\n  max-height: 100%;\n}\n\n.margin {\n  padding: 2rem 3rem;\n}\n\n.fillFrame {\n  height: 100%;\n}\n\n.hasFilterBar {\n  padding: 2.5rem 3.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "BaseContentContainer_root__2c3-S",
	"capHeight": "BaseContentContainer_capHeight__3ePIb",
	"margin": "BaseContentContainer_margin__XnSfX",
	"fillFrame": "BaseContentContainer_fillFrame__15itn",
	"hasFilterBar": "BaseContentContainer_hasFilterBar__4OD5G"
};
export default ___CSS_LOADER_EXPORT___;
