// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NOHEfficiencyLineChart_root__30Xdk {\n  width: 100%;\n  height: 300px;\n  padding-right: 2rem;\n}\n\n.NOHEfficiencyLineChart_yUnitLabel__humsY {\n  width: 2rem;\n  text-align: center;\n  margin-bottom: 0.5rem;\n  font-size: 1rem;\n  color: var(--base50);\n}", "",{"version":3,"sources":["webpack://src/views/report/NOHEfficiencyLineChart/NOHEfficiencyLineChart.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".root {\n  width: 100%;\n  height: 300px;\n  padding-right: 2rem;\n}\n\n.yUnitLabel {\n  width: 2rem;\n  text-align: center;\n  margin-bottom: 0.5rem;\n  font-size: 1rem;\n  color: var(--base50);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NOHEfficiencyLineChart_root__30Xdk",
	"yUnitLabel": "NOHEfficiencyLineChart_yUnitLabel__humsY"
};
export default ___CSS_LOADER_EXPORT___;
