// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowSitePageContainer_tabsContainer__3I3Xp {\n  padding: 0rem 3rem;\n}\n", "",{"version":3,"sources":["webpack://src/views/sites/ShowSitePageContainer/ShowSitePageContainer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB","sourcesContent":[".tabsContainer {\n  padding: 0rem 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": "ShowSitePageContainer_tabsContainer__3I3Xp"
};
export default ___CSS_LOADER_EXPORT___;
