// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SectionTitle_root__31EqI {\n  margin-bottom: 1rem;\n}\n\n.SectionTitle_title__3LDYZ {\n  color: var(--accent-base);\n  font-size: 2rem;\n  margin-bottom: 1rem;\n  margin-top: 4rem;\n}\n\n.SectionTitle_description__jht3z {\n  font-size: 1rem;\n  line-height: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/SectionTitle/SectionTitle.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".root {\n  margin-bottom: 1rem;\n}\n\n.title {\n  color: var(--accent-base);\n  font-size: 2rem;\n  margin-bottom: 1rem;\n  margin-top: 4rem;\n}\n\n.description {\n  font-size: 1rem;\n  line-height: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SectionTitle_root__31EqI",
	"title": "SectionTitle_title__3LDYZ",
	"description": "SectionTitle_description__jht3z"
};
export default ___CSS_LOADER_EXPORT___;
