// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateFilter_root__3ga29 {\n  display: flex;\n}\n\n.DateFilter_applyButton__otzBM {\n  margin: 0.5rem 0;\n  padding-left: 0rem;\n  float: right;\n}\n\n.DateFilter_customDateOpenContainerMargin__ngQLV {\n  margin: 0.5rem 1rem;\n}\n\n.DateFilter_customDateOpenContainerMargin__ngQLV > div {\n  margin: 0.5rem 0rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/Filters/DateFilter/DateFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  display: flex;\n}\n\n.applyButton {\n  margin: 0.5rem 0;\n  padding-left: 0rem;\n  float: right;\n}\n\n.customDateOpenContainerMargin {\n  margin: 0.5rem 1rem;\n}\n\n.customDateOpenContainerMargin > div {\n  margin: 0.5rem 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DateFilter_root__3ga29",
	"applyButton": "DateFilter_applyButton__otzBM",
	"customDateOpenContainerMargin": "DateFilter_customDateOpenContainerMargin__ngQLV"
};
export default ___CSS_LOADER_EXPORT___;
