// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DoorAccess_content__SxIZT {\n  display: flex;\n  flex-direction: column;\n}\n\n.DoorAccess_headerContainer__2QnRh {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\n.DoorAccess_cell__XV5p4 {\n  font-size: 0.875rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/GenericReport/DoorAccess/DoorAccess.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n}\n\n.headerContainer {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\n.cell {\n  font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "DoorAccess_content__SxIZT",
	"headerContainer": "DoorAccess_headerContainer__2QnRh",
	"cell": "DoorAccess_cell__XV5p4"
};
export default ___CSS_LOADER_EXPORT___;
