// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ColdStorageReduction_content__krkLL {\n  display: flex;\n  flex-direction: column;\n}\n\n.ColdStorageReduction_headerContainer__8S4xT {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\n.ColdStorageReduction_headerIcon__3n3dX {\n  color: var(--accent-base);\n  display: block;\n  padding-right: 1rem;\n}\n\n.ColdStorageReduction_cell__16DRL {\n  font-size: 0.875rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/GenericReport/ColdStorageReduction/ColdStorageReduction.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n}\n\n.headerContainer {\n  display: flex;\n  margin-bottom: 2rem;\n}\n\n.headerIcon {\n  color: var(--accent-base);\n  display: block;\n  padding-right: 1rem;\n}\n\n.cell {\n  font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ColdStorageReduction_content__krkLL",
	"headerContainer": "ColdStorageReduction_headerContainer__8S4xT",
	"headerIcon": "ColdStorageReduction_headerIcon__3n3dX",
	"cell": "ColdStorageReduction_cell__16DRL"
};
export default ___CSS_LOADER_EXPORT___;
