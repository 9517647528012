// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NOHEfficiencySummaryTile_tileContainer__1CeQo {\n  font-size: 1.2rem;\n  line-height: 1.5rem;\n  padding: 1rem;\n  background-color: rgba(42, 221, 208, 0.1);\n  border: 2px solid var(--accent-base);\n}", "",{"version":3,"sources":["webpack://src/views/report/NOHEfficiencySummaryTile/NOHEfficiencySummaryTile.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,yCAAyC;EACzC,oCAAoC;AACtC","sourcesContent":[".tileContainer {\n  font-size: 1.2rem;\n  line-height: 1.5rem;\n  padding: 1rem;\n  background-color: rgba(42, 221, 208, 0.1);\n  border: 2px solid var(--accent-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tileContainer": "NOHEfficiencySummaryTile_tileContainer__1CeQo"
};
export default ___CSS_LOADER_EXPORT___;
