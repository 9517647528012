// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SiteInfo_container__3dg82 {\n  padding: 2rem 0 1rem 3rem;\n}\n\n.SiteInfo_siteTitle__1OBbz {\n  margin-bottom: 0.5rem;\n  font-weight: bold;\n}\n\n.SiteInfo_siteAddress__3r9lD {\n  font-size: 0.875rem;\n  color: #5e5e5e;\n}\n", "",{"version":3,"sources":["webpack://src/components/SiteInfo/SiteInfo.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".container {\n  padding: 2rem 0 1rem 3rem;\n}\n\n.siteTitle {\n  margin-bottom: 0.5rem;\n  font-weight: bold;\n}\n\n.siteAddress {\n  font-size: 0.875rem;\n  color: #5e5e5e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SiteInfo_container__3dg82",
	"siteTitle": "SiteInfo_siteTitle__1OBbz",
	"siteAddress": "SiteInfo_siteAddress__3r9lD"
};
export default ___CSS_LOADER_EXPORT___;
