// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EnergyTariffTooltip_tooltipEnergyTariff__3fVKF {\n  margin-top: 0.75rem;\n  display: flex;\n  justify-content: space-between;\n  color: var(--sun-basePlus25);\n}\n", "",{"version":3,"sources":["webpack://src/components/Charts/EnergyTariffTooltip.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,4BAA4B;AAC9B","sourcesContent":[".tooltipEnergyTariff {\n  margin-top: 0.75rem;\n  display: flex;\n  justify-content: space-between;\n  color: var(--sun-basePlus25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipEnergyTariff": "EnergyTariffTooltip_tooltipEnergyTariff__3fVKF"
};
export default ___CSS_LOADER_EXPORT___;
