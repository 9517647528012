// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RechartsDonutChart_chartContainer__35cMb {\n  margin-top: 0.33em;\n  transform: 'translate(-7%, 0)';\n}\n\n.RechartsDonutChart_yUnitLabel__wTiqb {\n  margin-left: 4em;\n  margin-bottom: 1em;\n  font-size: 10px;\n  color: var(--base50);\n}\n\n.RechartsDonutChart_tooltipContainer__1m4Pp {\n  background-color: var(--ambient-base);\n  width: 10rem;\n}\n\n.RechartsDonutChart_tooltipInner__cvegx {\n  position: relative;\n  padding: 0.625rem 0.625rem 0.3rem 0.625rem;\n}\n\n.RechartsDonutChart_tooltipHeader__aa5Nn {\n  font-size: 0.75rem;\n  font-style: italic;\n  color: white;\n}\n\n.RechartsDonutChart_tooltipValues__2B1j9 {\n  margin-top: 0.75rem;\n  display: flex;\n  justify-content: space-between;\n  color: var(--accent-base);\n}\n\n.RechartsDonutChart_space__3aVcM {\n  height: 1.25em;\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://src/components/Charts/Donut/RechartsDonutChart.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,qCAAqC;EACrC,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,YAAY;AACd","sourcesContent":[".chartContainer {\n  margin-top: 0.33em;\n  transform: 'translate(-7%, 0)';\n}\n\n.yUnitLabel {\n  margin-left: 4em;\n  margin-bottom: 1em;\n  font-size: 10px;\n  color: var(--base50);\n}\n\n.tooltipContainer {\n  background-color: var(--ambient-base);\n  width: 10rem;\n}\n\n.tooltipInner {\n  position: relative;\n  padding: 0.625rem 0.625rem 0.3rem 0.625rem;\n}\n\n.tooltipHeader {\n  font-size: 0.75rem;\n  font-style: italic;\n  color: white;\n}\n\n.tooltipValues {\n  margin-top: 0.75rem;\n  display: flex;\n  justify-content: space-between;\n  color: var(--accent-base);\n}\n\n.space {\n  height: 1.25em;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": "RechartsDonutChart_chartContainer__35cMb",
	"yUnitLabel": "RechartsDonutChart_yUnitLabel__wTiqb",
	"tooltipContainer": "RechartsDonutChart_tooltipContainer__1m4Pp",
	"tooltipInner": "RechartsDonutChart_tooltipInner__cvegx",
	"tooltipHeader": "RechartsDonutChart_tooltipHeader__aa5Nn",
	"tooltipValues": "RechartsDonutChart_tooltipValues__2B1j9",
	"space": "RechartsDonutChart_space__3aVcM"
};
export default ___CSS_LOADER_EXPORT___;
