// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SiteReportsList_root__1nhsc {\n  padding: 0rem 3rem 2rem 3rem;\n}\n\n.SiteReportsList_headerContainer__1KnFC {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 1.25rem;\n}\n\n.SiteReportsList_leftHeader__3dhBu {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-top: 1em;\n}\n\n.SiteReportsList_headerTitle__3U97p {\n  padding-right: 1.5rem;\n  font-size: 1.25rem;\n  font-weight: bold;\n}\n\n.SiteReportsList_report__3yTGO {\n  padding-left: 0.5rem;\n}\n\n.SiteReportsList_linkContainer__2BjUn {\n  margin-top: 1.25rem;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://src/views/sites/SiteReportsList/SiteReportsList.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".root {\n  padding: 0rem 3rem 2rem 3rem;\n}\n\n.headerContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 1.25rem;\n}\n\n.leftHeader {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-top: 1em;\n}\n\n.headerTitle {\n  padding-right: 1.5rem;\n  font-size: 1.25rem;\n  font-weight: bold;\n}\n\n.report {\n  padding-left: 0.5rem;\n}\n\n.linkContainer {\n  margin-top: 1.25rem;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SiteReportsList_root__1nhsc",
	"headerContainer": "SiteReportsList_headerContainer__1KnFC",
	"leftHeader": "SiteReportsList_leftHeader__3dhBu",
	"headerTitle": "SiteReportsList_headerTitle__3U97p",
	"report": "SiteReportsList_report__3yTGO",
	"linkContainer": "SiteReportsList_linkContainer__2BjUn"
};
export default ___CSS_LOADER_EXPORT___;
