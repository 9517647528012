// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentUserLogs_root__3UtUU table.CommentUserLogs_table__2EMYU thead tr {\n  font-size: 1rem;\n  border-top: 3px solid var(--ambient-basePlus90);\n  border-bottom: 3px solid var(--ambient-basePlus90);\n}\n\n.CommentUserLogs_root__3UtUU table.CommentUserLogs_table__2EMYU tbody tr:nth-child(even) {\n  background-color: var(--ambient-basePlus90);\n}\n\n.CommentUserLogs_table__2EMYU {\n  font-size: 1.5rem;\n}\n\n.CommentUserLogs_tableHeader__1NfgK {\n  font-size: 1.5rem;\n  font-weight: bold;\n  line-height: 3rem;\n}\n\n.CommentUserLogs_paddingTop__28uSQ {\n  padding-top: 0.25rem;\n}\n\n.CommentUserLogs_smallGreyText__3n0KP {\n  font-size: 1.125rem;\n  font-style: italic;\n  color: var(--ambient-basePlus25);\n}\n\n.CommentUserLogs_semiBold__1GUlD {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://src/containers/EquipmentReport/CommentUserLogs/CommentUserLogs.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,+CAA+C;EAC/C,kDAAkD;AACpD;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root table.table thead tr {\n  font-size: 1rem;\n  border-top: 3px solid var(--ambient-basePlus90);\n  border-bottom: 3px solid var(--ambient-basePlus90);\n}\n\n.root table.table tbody tr:nth-child(even) {\n  background-color: var(--ambient-basePlus90);\n}\n\n.table {\n  font-size: 1.5rem;\n}\n\n.tableHeader {\n  font-size: 1.5rem;\n  font-weight: bold;\n  line-height: 3rem;\n}\n\n.paddingTop {\n  padding-top: 0.25rem;\n}\n\n.smallGreyText {\n  font-size: 1.125rem;\n  font-style: italic;\n  color: var(--ambient-basePlus25);\n}\n\n.semiBold {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CommentUserLogs_root__3UtUU",
	"table": "CommentUserLogs_table__2EMYU",
	"tableHeader": "CommentUserLogs_tableHeader__1NfgK",
	"paddingTop": "CommentUserLogs_paddingTop__28uSQ",
	"smallGreyText": "CommentUserLogs_smallGreyText__3n0KP",
	"semiBold": "CommentUserLogs_semiBold__1GUlD"
};
export default ___CSS_LOADER_EXPORT___;
