// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OperationalSensorChartContainer_chartTitle__3240b {\n  font-size: 2rem;\n  color: var(--accent-base);\n  margin-bottom: 1rem;\n}\n\n.OperationalSensorChartContainer_marginTop__rhHgL {\n  margin-top: 0.75rem;\n}\n", "",{"version":3,"sources":["webpack://src/containers/EquipmentReport/OperationalSensorChartContainer/OperationalSensorChartContainer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".chartTitle {\n  font-size: 2rem;\n  color: var(--accent-base);\n  margin-bottom: 1rem;\n}\n\n.marginTop {\n  margin-top: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartTitle": "OperationalSensorChartContainer_chartTitle__3240b",
	"marginTop": "OperationalSensorChartContainer_marginTop__rhHgL"
};
export default ___CSS_LOADER_EXPORT___;
