// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EquipmentChartAnalyticsContainer_root__2j_0t {\n  width: 100%;\n  padding: 0.75rem 3rem;\n  background-color: var(--ambient-basePlus75);\n  margin-bottom: 0.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 1.5rem;\n}\n\n.EquipmentChartAnalyticsContainer_label__3OlrP {\n  font-weight: bold;\n  margin-right: 0.75rem;\n}\n\n.EquipmentChartAnalyticsContainer_textContainer__RsgRC {\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://src/containers/EquipmentReport/EquipmentChartAnalyticsContainer/EquipmentChartAnalyticsContainer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;EACrB,2CAA2C;EAC3C,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".root {\n  width: 100%;\n  padding: 0.75rem 3rem;\n  background-color: var(--ambient-basePlus75);\n  margin-bottom: 0.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 1.5rem;\n}\n\n.label {\n  font-weight: bold;\n  margin-right: 0.75rem;\n}\n\n.textContainer {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EquipmentChartAnalyticsContainer_root__2j_0t",
	"label": "EquipmentChartAnalyticsContainer_label__3OlrP",
	"textContainer": "EquipmentChartAnalyticsContainer_textContainer__RsgRC"
};
export default ___CSS_LOADER_EXPORT___;
